.swiper-button-next,
.swiper-button-prev {
  position: absolute;
  background-color: white;
  color: rgb(253, 220, 89) !important;
  border-radius: 50%;
  padding: 8px 16px;
  width: 40px !important;
  height: 40px !important;
  z-index: 9 !important;
}
.swiper-button-prev {
  left: 20px !important;
}
.swiper-button-next {
  right: 20px !important;
}

.swiper-button-next::after {
  font-size: 23px !important;
  font-weight: bolder !important;
}

.swiper-button-prev::after {
  font-size: 23px !important;
  font-weight: bolder !important;
}

.mySwiper {
  position: unset !important;
  width: 90%;
}

.swiper-slide {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

@media (max-width: 640px) {
  .swiper-button-next,
  .swiper-button-prev {
    width: 50px !important;
    height: 50px !important;
  }
  .swiper-button-prev {
    top: 100% !important;
    left: 40% !important;
  }
  .swiper-button-next {
    top: 100% !important;
    right: 40% !important;
  }

  .swiper-button-next::after {
    font-size: 26px !important;
  }
  .swiper-button-prev::after {
    font-size: 26px !important;
  }
}

@media (max-width: 575px) {
  .swiper-button-prev {
    top: 100% !important;
    left: 38% !important;
  }
  .swiper-button-next {
    top: 100% !important;
    right: 38% !important;
  }
}

@media (max-width: 460px) {
  .swiper-button-prev {
    top: 100% !important;
    left: 35% !important;
  }
  .swiper-button-next {
    top: 100% !important;
    right: 35% !important;
  }
}
@media (max-width:420px){
  .mySwiper {
    width: 99%;
  }
}
@media (max-width: 375px) {
  .swiper-button-prev {
    top: 100% !important;
    left: 34% !important;
  }
  .swiper-button-next {
    top: 100% !important;
    right: 34% !important;
  }
}