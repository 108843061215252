@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: rgb(253, 220, 89);
  font-family: "Montserrat", sans-serif;
}

.bg-yellow-200 {
  background-color: rgb(253, 220, 89);
}
